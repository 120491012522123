import * as React from "react";
import { graphql, Link } from "gatsby";
import { motion } from "framer-motion";

import * as indexStyles from "./__styles/index.module.scss";

import Pattern from "../components/pattern";
import { NavContext } from "../components/nav";
import Meta from "../components/meta";
import AppsContainer from "../components/apps";

const IndexPage = ({ data }) => {
  const navContext = React.useContext(NavContext);
  React.useEffect(() => {
    navContext.setNav([]);
  }, []);

  return (
    <motion.div
      className="sidebar-left"
      initial={{ opacity: 0 }}
      variants={{
        enter: { opacity: 1, transition: { duration: 0.4 } },
        exit: { opacity: 0, transition: { duration: 0.2 } },
      }}
    >
      <Meta
        title={`Quentin Golsteyn`}
        description="The personal website of Quentin Golsteyn, a front-end developer based in Vancouver, Canada."
        canonical={`https://golsteyn.com/`}
      />
      <aside>
        <div className={indexStyles.aside__illustration}>
          <Pattern positionRange={[20, 80]} sizeRange={[100, 500]} />
        </div>
        <div className={indexStyles.aside__title}>
          <h1 className="h4 title">Quentin Golsteyn</h1>
          <span className="subtitle">
            Front-end developer based in Vancouver, Canada.
          </span>
        </div>
        <div className={indexStyles.aside__apps}>
          <h2 className="h6 section-header">My apps</h2>
          <AppsContainer />
        </div>
      </aside>
      <article>
        <section className={indexStyles.postSection}>
          <h2 className="h4 section-header">Posts</h2>
          {data.allJavascriptFrontmatter.nodes.map((node) => (
            <Link
              key={node.id}
              className={indexStyles.post}
              to={"/writing/" + node.node.name}
            >
              <h3 className="h6 title underline">{node.frontmatter.title}</h3>
              <span className="subtitle">{node.frontmatter.subtitle}</span>
              <span className="meta">{node.frontmatter.meta}</span>
            </Link>
          ))}
        </section>
      </article>
    </motion.div>
  );
};

export const query = graphql`
  {
    allJavascriptFrontmatter(
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        id
        frontmatter {
          title
          subtitle
          meta
        }
        node {
          name
        }
      }
    }
  }
`;

export default IndexPage;
